<template>
  <div id="help">
    <h1 class="title is-2">{{ $t('nav.faq')}}</h1>
    <div class="container">
      <VueFaqAccordion :items="$t('faq')"/>
    </div>
    <!--<div class="box container content has-text-justified" v-html="$t('doc')">
    </div>-->
  </div>
</template>

<script>
import VueFaqAccordion from 'vue-faq-accordion';

export default {
  name: 'Help',
  metaInfo(){
    return {
      link: this.$getAlternates('help'),
    };
  },
  components: {
    VueFaqAccordion,
  },
}
</script>

<style lang="scss">
/* no scope. Set faq wrapper width at 100% inside vuefaqaccordion component */
.faq-wrapper{
  width: 100%;
  max-width: 100% !important;
}
</style>
